<!--
 * @Author: your name
 * @Date: 2020-12-14 13:18:30
 * @LastEditTime: 2021-02-01 17:49:45
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\transit\index.vue
-->
<template>
    <div>
        <h1>{{loadmsg}}</h1>
    </div>
</template>
<script>
export default {
    data(){
        return{
            loadmsg:"验证中，请稍后......"
        }
    },
    created(){
        var that = this;
        this.$http.post("/firm/v1/login/qrCodeInfo",{
            qr_id:window.atob(this.$route.query.qrId)
        })
        .then(res=>{
            
            let allmsg = JSON.parse(res.data).data.qr_info;
            if(allmsg.status=="1"){
                this.$router.push({
                    path:"backcenter",
                    query:{
                        qrId:this.$route.query.qrId+"/",
                        isQrcode:"true"
                    }
                })
            }else{
                this.loadmsg = "该二维码已禁用..."
                setTimeout(function(){
                    that.$router.push("/backrefuse");
                }, 1000);
            }
        })
    }
}
</script>
<style scoped>
h1{
    text-align: center;
    margin-top: 1rem;
}
</style>